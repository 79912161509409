/** @format */

import React, { lazy, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));
const CookiePolicy = lazy(() => import('./CookiePolicy'));
const Main = lazy(() => import('./Main'));
const MainSuccess = lazy(() => import('./Main/Form/Success'));
const ThankPageMain = lazy(() => import('./Main/Thanks'));

const Demo = lazy(() => import('./Demo'));
const DemoSuccess = lazy(() => import('./Demo/Form/Success'));
const ThankPageDemo = lazy(() => import('./Demo/Thanks'));
const BuyAHomeWithSuper = lazy(() => import('./BuyAHomeWithSuper'));
const BuyAHomeWithSuperSuccess = lazy(() => import('./BuyAHomeWithSuper/Form/Success'));
const ThankPageBuyAHomeWithSuper = lazy(() => import('./BuyAHomeWithSuper/Thanks'));
const Router = () => {
  const { listen } = useHistory();
  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      window.gtag("config", "GTM-TC9KTLT", {
        page_path: location.pathname,
      });
    });
    return unlisten;
  }, [listen]);

  return (
    <Switch>
      <Route exact path="/thank-you">
        <ThankPageMain />
      </Route>
      <Route exact path="/thank-you-demo">
        <ThankPageDemo />
      </Route>
      <Route exact path="/thank-you-buy-a-home-with-super">
        <ThankPageBuyAHomeWithSuper />
      </Route>
      <Route exact path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/terms-and-conditions">
        <TermsAndConditions />
      </Route>
      <Route exact path="/cookie-policy">
        <CookiePolicy />
      </Route>
      <Route exact path="/demo/step-success">
        <DemoSuccess/>
      </Route>
      <Route exact path="/demo">
        <Demo />
      </Route>
      <Route exact path="/demo*">
        <Demo />
      </Route>
      <Route exact path="/buy-a-home-with-super/step-success">
        <BuyAHomeWithSuperSuccess/>
      </Route>
      <Route exact path="/buy-a-home-with-super">
        <BuyAHomeWithSuper />
      </Route>
      <Route exact path="/buy-a-home-with-super*">
        <BuyAHomeWithSuper />
      </Route>
      {/* <Route path="/">
        <HomePage />
      </Route> */}
      <Route exact path="/step-success">
        <MainSuccess/>
      </Route>
      <Route exact path="/">
        <Main />
      </Route>
      <Route exact path="/*">
        <Main />
      </Route>
    </Switch>
  );
};

export default Router;
