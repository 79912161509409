/** @format */

import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./app.scss";
import Router from "./pages/Router";

export default function App() {
  useEffect(() => {
    document.title = "Enquiry Realee.com.au";
    localStorage.removeItem("firstname");
    localStorage.removeItem("lastname");
    localStorage.removeItem("email");
  }, []);
  return (
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Router />
      </Suspense>
    </BrowserRouter>
  );
}
